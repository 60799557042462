<template>
  <TransitionSlide
    :enter-from="TRANSITION_POSITIONS.TOP"
    :enter-to="TRANSITION_POSITIONS.CENTER"
    :leave-to="TRANSITION_POSITIONS.TOP"
  >
    <div class="fixed top-16 z-40 h-12 w-full lg:h-16" v-if="y >= 258 * 0.8">
      <NuxtImg
        :src="image.url"
        :alt="t('alt', { title })"
        class="h-full w-full object-cover brightness-125 dark:brightness-50"
      />
      <div
        class="dark:bg-dark/50 absolute inset-0 bg-white/50 backdrop-blur-sm"
      />

      <div class="absolute inset-0 flex items-center justify-center">
        <span
          class="~text-lg/xl text-dark dark:text-light w-full text-center font-bold leading-none"
          v-text="title"
        />
      </div>
    </div>
  </TransitionSlide>
</template>

<script setup lang="ts">
import type { Image } from '#gql/default'
import { TRANSITION_POSITIONS } from '@base/constants/transition-positions'

interface Props {
  image: Image
  title: string
}

defineProps<Props>()

const { y } = useWindowScroll()
const { t } = useI18n()

defineOptions({
  name: 'PageHeaderSlideIn',
})
</script>

<i18n>
de:
  alt: "{title} Banner"
es:
  alt: "Banner de {title}"
</i18n>
