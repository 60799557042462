<template>
  <div class="relative" data-google-maps>
    <ConsentManagerWarning
      v-if="!hasConsent"
      :can-be-activated="true"
      :foreign-domains="['maps.googleapis.com']"
      class="h-full w-full"
    />

    <div v-else id="google-map-container" class="h-full w-full" />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema?: Cinema
  address?: string
  zoom?: number
}

const props = defineProps<Props>()

const { getConsentForDomain } = useConsentManager()
const hasConsent = computed(() => getConsentForDomain('maps.googleapis.com'))
const { locale } = useI18n()

const runtimeConfig = useRuntimeConfig()

let infoWindow: any
let map: any
let service: any

function loadGoogleMapsScript() {
  useScriptTag(
    `https://maps.googleapis.com/maps/api/js?key=${runtimeConfig.public.GOOGLE_MAPS_API_KEY}&language=${locale.value}&libraries=places`,
    async () => {
      await nextTick()

      initMap()
    },
    {
      async: true,
    }
  )
}

function initMap() {
  const location = new window.google.maps.LatLng(
    props.cinema.latitude,
    props.cinema.longitude
  )

  infoWindow = new window.google.maps.InfoWindow()

  map = new window.google.maps.Map(
    document.getElementById('google-map-container'),
    {
      center: location,
      zoom: props.zoom ?? 15,
      disableDefaultUI: true,
      styles: [
        { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
        {
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#242f3e' }],
        },
        { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#263c3f' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#6b9a76' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#38414e' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#212a37' }],
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9ca5b3' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#746855' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#1f2835' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#f3d19c' }],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#2f3948' }],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#d59563' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#17263c' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#515c6d' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#17263c' }],
        },
      ],
    }
  )

  service = new window.google.maps.places.PlacesService(map)
  service.findPlaceFromQuery(
    {
      query: props.cinema.name,
      fields: ['name', 'geometry'],
    },
    (results, status) => {
      if (
        status === window.google?.maps?.places?.PlacesServiceStatus?.OK &&
        results
      ) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i])
        }

        map.setCenter(results[0].geometry.location)
      }
    }
  )
}

function createMarker(place: {
  geometry: { location: { lat: () => number; lng: () => number } }
}) {
  if (!place.geometry || !place.geometry.location) return

  const marker = new window.google.maps.Marker({
    map,
    position: place.geometry.location,
    title: props.cinema.name,
  })

  window.google.maps.event.addListener(marker, 'click', () => {
    infoWindow.setContent(place.name || '')
    infoWindow.open(map)
  })
}

onMounted(async () => {
  await nextTick()

  if (hasConsent.value) {
    loadGoogleMapsScript()
  }
})

onUnmounted(() => {
  window.google = null
})

watch(hasConsent, () => {
  if (hasConsent.value) {
    loadGoogleMapsScript()
  } else {
    window.google = null
  }
})

defineOptions({
  name: 'GoogleMaps',
})
</script>
