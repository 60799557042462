<template>
  <PageContainer>
    <PageGrid>
      <template #default="{ colSpacing }">
        <PageGridColumn :class="colSpacing">
          <PageHeading :title="t('page.title', { cityName: city.name })" />
        </PageGridColumn>

        <PageGridColumn :class="colSpacing">
          <PageSectionHeading
            :title="
              t('section.title.cinemas', {
                cityName: props.city.name,
                distance,
              })
            "
            class="mb-4"
          />
          <CatalogCinemas
            :distance="distance"
            :city="city.urlSlug"
            :layout="CINEMA_CARDS_LAYOUT.HORIZONTAL"
            :render-above-the-fold="true"
            :filters="[URL_FILTER.SEARCH, URL_FILTER.TYPES]"
            :fetch-fallback-results="true"
            :items-per-page="15"
            url-prefix="c"
          />
        </PageGridColumn>

        <PageGridColumn :class="colSpacing" class="mt-8">
          <PageSectionHeading
            :title="
              t('section.title.movies', { cityName: city.name, distance })
            "
            class="mb-4"
          />
          <CatalogMovies
            :layout="MOVIES_LAYOUT.VERTICAL"
            :distance="distance"
            :city="city.urlSlug"
            :filters="[
              URL_FILTER.SEARCH,
              URL_FILTER.PERIODS,
              URL_FILTER.GENRES,
              URL_FILTER.CONTENT_RATINGS,
            ]"
            url-prefix="m"
          />
        </PageGridColumn>
      </template>
    </PageGrid>
  </PageContainer>
</template>

<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
  distance: number
}

const props = defineProps<Props>()

const { t } = useI18n()
const { $gtm } = useNuxtApp()

usePageMetas({
  title: t('page.title', { cityName: props.city.name }),
  description: t('page.description', { cityName: props.city.name }),
})

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'GenericPageCity',
})
</script>

<i18n>
de:
  page:
    title: "Kinos & Filme in {cityName}"
    description: "Jetzt bei kinoheld Kinotickets in der Nähe von {cityName} schnell und bequem online kaufen."
  section:
    title:
      cinemas: "Kinos in {cityName} und Umgebung ({distance}km)"
      movies: "Filme in {cityName} und Umgebung ({distance}km)"
es:
  page:
    title: "Cines & Películas en {cityName}"
    description: "Compra tus entradas de cine en {cityName} y alrededores de forma rápida y cómoda en línea con entradas."
  section:
    title:
      cinemas: "Cines en {cityName} y alrededores ({distance}km)"
      movies: "Películas en {cityName} y alrededores ({distance}km)"
</i18n>
