<template>
  <CatalogProgram
    v-if="!pending && movie"
    :filters="[
      URL_FILTER.DATES,
      URL_FILTER.LANGUAGE,
      URL_FILTER.TECHNOLOGY,
      URL_FILTER.EVENT,
      URL_FILTER.MISCELLANEOUS,
    ]"
    :layout="PROGRAM_LAYOUT.GROUPED_BY_MOVIE"
    :items-per-page="10"
    :cinema-ids="[cinema.id]"
    :show-groups="[urlSlug]"
    :fetch-fallback-results="true"
    :link-params="[]"
    :preserve-filters="false"
    :booking-modal="false"
    :render-above-the-fold="false"
  />
</template>

<script setup lang="ts">
import { URL_FILTER } from '@base/constants/url-filter'
import { PROGRAM_LAYOUT } from '@base/constants/program'
import type { Cinema, ProgramGroupByMovie } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()
const { t } = useI18n()
const route = useRoute()

const { results, pending, error } = await useProgram({
  fetchOperation: 'FetchProgramForCinemaShowGroup',
  fetchFallbackResults: false,
  preselected: {
    cinemaIds: [cinema.id],
    showGroups: [route.params.showGroupSlug as string],
  },
  first: 1,
})

const groups = computed(() => results.value as unknown as ProgramGroupByMovie[])
const movie = computed(() => groups.value?.[0]?.movie)
const urlSlug = computed(() => groups.value?.[0]?.urlSlug)

if (error.value || (!movie.value && !pending.value)) {
  const { redirectWithError } = useRedirectError()

  await redirectWithError({
    message: error.value ?? REDIRECT_ERROR_STRINGS.NO_MOVIE,
    redirect: {
      name: ROUTE_NAMES.CINEMA_PROGRAM,
      params: {
        citySlug: route.params.citySlug as string,
        cinemaSlug: route.params.cinemaSlug as string,
      },
    },
    cinemaId: cinema.id,
  })
}

usePageMetas({
  title: `${cinema.name} | ${t('tickets')}`,
  description: t('description', {
    cinemaName: cinema.name,
    movieTitle: movie.value?.title,
  }),
  ...movie.value?.meta,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]/film/[showGroupSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugMovieShowGroupSlug',
})
</script>

<i18n>
de:
  tickets: "Kinotickets"
  description: "Jetzt bei kinoheld Kinotickets für das Kino {cinemaName} und den Film {movieTitle} schnell und bequem online kaufen."
es:
  tickets: "Cartelera y horarios"
  description: "Compra tus entradas de cine para el cine {cinemaName} y la película {movieTitle} de forma rápida y cómoda en entradas."
</i18n>
