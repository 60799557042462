<template>
  <component :is="disabled ? 'div' : NuxtLink" v-bind="componentProps">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { Cinema, LinkParam } from '#gql/default'

interface Props {
  cinema: Cinema
  linkParams?: LinkParam[]
  disabled?: boolean
}

const props = defineProps<Props>()
const NuxtLink = resolveComponent('NuxtLink')
const componentProps = computed(() =>
  props.disabled ? {} : { to: detailLink.value }
)

const localeRoute = useLocaleRoute()
const detailLink = computed(() =>
  localeRoute({
    name: ROUTE_NAMES.CINEMA_PROGRAM,
    params: {
      citySlug: props.cinema.city.urlSlug,
      cinemaSlug: props.cinema.urlSlug,
    },
    query: {
      ...props.linkParams?.reduce(
        (acc, { key, value }) => {
          acc[key] = value
          return acc
        },
        {} as Record<string, string>
      ),
    },
  })
)

defineOptions({
  name: 'CinemaLink',
})
</script>
