<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPageCity v-else :city :distance="25" />
  </div>
</template>

<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
}

defineProps<Props>()

const { page } = useCms()

defineOptions({
  name: 'PageCinemaCitySlugIndex',
})
</script>
