<template>
  <div class="relative flex flex-col">
    <div class="mb-4 animate-pulse space-y-3">
      <div class="relative aspect-square">
        <div
          class="rounded-full"
          :class="[cssClasses.color, cssClasses.image]"
        />
      </div>

      <div class="flex flex-col items-center justify-center space-y-1">
        <div class="w-24" :class="[cssClasses.color, cssClasses.text]" />
        <div class="w-12" :class="[cssClasses.color, cssClasses.text]" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'CinemaCardSkeleton',
})
</script>
