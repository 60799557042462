<template>
  <div data-movie-catalog class="space-y-6">
    <LazyFilters v-if="filters.length" v-bind="filterProps" />

    <Suspense>
      <div class="space-y-6">
        <template v-if="!hasPreviousPages && pending">
          <LazyCarouselSkeleton v-if="layout === CAROUSEL" />
          <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
          <LazyMovieCardsSkeleton
            v-else
            :layout="movieCardsLayout"
            :render-skeletons
          />
        </template>

        <template v-else-if="hasPreviousPages || !pending">
          <EmptyResultHandler
            v-if="!pending && (hasFallbackResults || !movies?.length)"
            :type="EMPTY_RESULT_TYPE.CINEMAS"
            :has-filters-applied
            :url-prefix
            :has-fallback-results
            class="mt-4"
          />

          <LazyMovieCarousel
            v-if="layout === CAROUSEL"
            :movies
            :infinite
            :slide-duration
            :autoplay
            :link-params
            :render-above-the-fold
          />
          <LazyMovieList
            v-else-if="layout === MOVIE_LIST"
            :movies
            :link-params
            :render-above-the-fold
          />
          <LazyMovieCardsHorizontal
            v-else-if="layout === HORIZONTAL"
            :movies
            :render-above-the-fold
            :link-params
          />
          <LazyMovieCardsVertical
            v-else
            :movies
            :render-above-the-fold
            :link-params
          />

          <FetchMoreButton
            v-if="showFetchMoreButton"
            :pending
            :enable-pagination-on-scroll
            class="col-span-full"
            @click="fetchMore"
            @fetch-on-scroll="fetchMore"
          />
        </template>
      </div>
      <template #fallback>
        <LazyCarouselSkeleton v-if="layout === CAROUSEL" />
        <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
        <LazyMovieCardsSkeleton
          v-else
          :layout="movieCardsLayout"
          :render-skeletons
        />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import type { MovieInterface, LinkParam } from '#gql/default'

interface Props {
  layout?: MoviesLayout
  linkParams?: LinkParam[]
  filters?: UrlFilter[]
  renderAboveTheFold?: boolean
  fetchFallbackResults?: boolean
  itemsPerPage?: number
  slideDuration?: number
  preserveFilters?: boolean
  enablePagination?: boolean
  enablePaginationOnScroll?: boolean
  autoplay?: boolean
  infinite?: boolean
  city?: string
  periods?: string[]
  contentRatings?: string[]
  genres?: string[]
  search?: string
  urlPrefix?: string
}

const props = withDefaults(defineProps<Props>(), {
  filters: () => [],
  layout: MOVIES_LAYOUT.VERTICAL,
  linkParams: () => [],
  urlPrefix: '',
  fetchFallbackResults: false,
  itemsPerPage: 20,
  slideDuration: 5000,
  autoplay: true,
  infinite: true,
  renderAboveTheFold: false,
  enablePagination: true,
  enablePaginationOnScroll: false,
  preserveFilters: false,
  city: undefined,
  search: undefined,
  genres: undefined,
  periods: undefined,
  contentRatings: undefined,
})
const { t } = useI18n()

const { VERTICAL, HORIZONTAL, CAROUSEL, MOVIE_LIST } = MOVIES_LAYOUT

const fetchOperation = computed(() =>
  props.layout === CAROUSEL
    ? 'FetchMoviesForMovieCarousel'
    : props.layout === MOVIE_LIST
      ? 'FetchMoviesForMovieList'
      : 'FetchMoviesForMovieCards'
)
const showFetchMoreButton = computed(
  () =>
    props.enablePagination &&
    hasMorePages.value &&
    props.layout !== HORIZONTAL &&
    props.layout !== CAROUSEL
)

const {
  results,
  pending,
  hasMorePages,
  hasFallbackResults,
  hasPreviousPages,
  hasFiltersApplied,
  fetchMore,
  fetchParams,
} = await useMovies({
  fetchOperation: fetchOperation.value,
  filters: props.filters,
  urlPrefix: props.urlPrefix,
  fetchFallbackResults: props.fetchFallbackResults,
  preselected: {
    city: props.city,
    genres: props.genres,
    search: props.search,
    periods: props.periods,
  },
  first: props.itemsPerPage,
})

const movies = computed(() => results.value as any as MovieInterface[])

const renderSkeletons = computed(() => props.itemsPerPage ?? 4)
const movieCardsLayout = computed(() =>
  props.layout === HORIZONTAL ? HORIZONTAL : VERTICAL
)

// @TODO should be refactored into a useFilters composable
const filterProps = computed(() => ({
  urlPrefix: props.urlPrefix,
  fetchComponent: resolveComponent('FiltersDataProviderMovies'),
  fetchParams: {},
  filters: props.filters,
  searchPlaceholder: t('placeholder.search'),
  preselected: {
    city: props.city,
    genres: props.genres,
    search: props.search,
  },
}))

const linkParams = computed(() => {
  const params = [...(props.linkParams ?? [])]

  if (props.preserveFilters) {
    updateOrAddLinkParam(
      params,
      URL_FILTER.CITY,
      fetchParams.value.city as string
    )
  }

  return params.filter((param) => param.value)
})

defineOptions({
  name: 'CatalogMovies',
})
</script>

<i18n>
de:
  placeholder:
    search: "Film suchen"
es:
  placeholder:
    search: "Buscar película"
</i18n>
