<template>
  <div data-cinema-cards class="space-y-6">
    <Suspense>
      <div class="space-y-6">
        <CinemaCardsSkeleton
          v-if="pending && !hasPreviousPages"
          :layout
          :render-skeletons="itemsPerPage ?? 4"
        />

        <EmptyResultHandler
          v-if="!pending && (hasFallbackResults || !cinemas?.length)"
          :type="EMPTY_RESULT_TYPE.CINEMAS"
          :has-filters-applied
          :url-prefix
          :has-fallback-results
          class="mt-4"
        />

        <template v-if="layout === CINEMA_CARDS_LAYOUT.VERTICAL">
          <CinemaCardsVertical
            v-if="hasPreviousPages || (!pending && cinemas?.length)"
            :cinemas
            :link-params
          />
          <FetchMoreButton
            v-if="hasMorePages"
            :pending
            class="col-span-full"
            @click="fetchMore"
            @fetch-on-scroll="fetchMore"
          />
        </template>

        <template v-else>
          <CinemaCardsHorizontal
            v-if="hasPreviousPages || (!pending && cinemas?.length)"
            :cinemas
            :link-params
          />
        </template>
      </div>
      <template #fallback>
        <CinemaCardsSkeleton
          v-if="pending && !hasPreviousPages"
          :layout
          :render-skeletons="itemsPerPage ?? 4"
        />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import type { LinkParam } from '#gql/default'

export interface CinemaCardsQueryFilters {
  city?: string
  types?: string[]
  featureFlags?: string[]
  search?: string
}

interface Props {
  layout: CinemaCardsLayout
  filters?: UrlFilter[]
  linkParams?: LinkParam[]
  urlPrefix?: string
  fetchFallbackResults?: boolean
  preserveFilters?: boolean
  distance?: number
  itemsPerPage?: number
  enablePagination?: boolean
  renderAboveTheFold?: boolean
}

const props = defineProps<Props & CinemaCardsQueryFilters>()

const {
  cinemas,
  pending,
  hasMorePages,
  hasFallbackResults,
  hasPreviousPages,
  hasFiltersApplied,
  fetchMore,
} = await useCinemas({
  fetchOperation: 'FetchCinemasForCinemaCards',
  filters: props.filters,
  urlPrefix: props.urlPrefix,
  fetchFallbackResults: props.fetchFallbackResults,
  preselected: {
    city: props.city,
    types: props.types,
    search: props.search,
    featureFlags: props.featureFlags,
  },
  first: props.itemsPerPage,
})

defineOptions({
  name: 'CinemaCards',
})
</script>
