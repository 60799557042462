<template>
  <CinemaLink
    :cinema
    :link-params
    :disabled="hideLink"
    class="text-dark dark:text-light flex flex-col text-center text-sm font-light"
    :class="{ 'hover:underline': !hideLink }"
  >
    <CinemaLogo
      data-image
      :image-object
      :image-config
      :rounded="ROUNDED_TYPE.FULL"
    />
    <div
      class="break-words pt-3 text-center font-bold leading-tight"
      v-text="cinema.name"
    />
    <div
      class="break-words text-center leading-tight"
      v-text="cinema.city.name"
    />
  </CinemaLink>
</template>

<script setup lang="ts">
import type { Cinema, LinkParam } from '#gql/defaults'

interface Props {
  cinema: Cinema
  hideLink?: boolean
  linkParams?: LinkParam[]
  renderAboveTheFold?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  linkParams: () => [],
  hideLink: false,
  renderAboveTheFold: false,
})
const imageObject = getImage(props.cinema, 'thumbnailImage', props.cinema.name)

const config = useAppConfig()
const imageConfig = computed(() => ({
  ...config.images.cinemaCard.logo,
  loading: props.renderAboveTheFold ? IMAGE_LOADING.EAGER : IMAGE_LOADING.LAZY,
}))

defineOptions({
  name: 'CinemaCard',
})
</script>
