<template>
  <slot :filter-options :pending />
</template>

<script setup lang="ts">
export interface FiltersDataProviderCinemasFetchParams {
  proximity?: {
    city: string
  }
}

interface Props {
  urlPrefix?: string
  filters: UrlFilter[]
  fetchParams?: FiltersDataProviderCinemasFetchParams
}

const COMPONENT_NAME = 'FiltersDataProviderCinemas'
const props = withDefaults(defineProps<Props>(), {
  urlPrefix: '',
  fetchParams: () => ({}),
})
const params = toRef(props.fetchParams)

const { locale } = useI18n()
useGqlHeaders({ 'Accept-Language': locale.value })
const { data, refresh, pending } = useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.urlPrefix),
  () =>
    GqlFetchCinemasFilters({
      ...params.value,
      filters: props.filters,
    }),
  {
    immediate: false,
  }
)

const filterOptions = computed(() => data.value?.cinemas?.filterOptions ?? [])

watch(
  params,
  async (newParams, oldParams) => {
    if (!newParams || deepEqual(newParams, oldParams)) {
      return
    }

    await refresh()
  },
  { immediate: true }
)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
