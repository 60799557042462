<template>
  <div class="space-y-8 md:flex-wrap">
    <div
      class="flex w-full flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0"
    >
      <div
        data-cinema-info-tiles-container
        class="grid-rows-auto grid grid-cols-1 gap-4 md:w-1/2 lg:grid-cols-2 lg:grid-rows-[auto,auto]"
      >
        <CinemaInfoTile :icon="SVG_ICON.MAP_MARKER_OUTLINE" class="order-1">
          <span
            class="text-dark lg:text-center lg:text-white dark:text-white lg:dark:text-white"
            v-text="cinema.street"
          />
          <span class="text-dark lg:hidden dark:text-white">,&nbsp;</span>
          <span
            class="text-dark lg:text-center lg:text-white dark:text-white lg:dark:text-white"
            v-text="`${cinema.postcode.postcode} ${cinema.postcode.city.name}`"
          />
        </CinemaInfoTile>

        <CinemaInfoTile
          v-if="cinema.phonenumber"
          :icon="SVG_ICON.PHONE"
          class="order-2 lg:order-3"
        >
          <span
            v-text="cinema.phonenumber"
            class="text-dark lg:text-light dark:text-white lg:dark:text-white"
          />
        </CinemaInfoTile>

        <CinemaInfoTile
          v-if="cinema.url"
          :icon="SVG_ICON.WEB"
          class="order-3 lg:order-2"
        >
          <NuxtLink
            :to="cinema.url!"
            :target="LINK_TARGET.BLANK"
            :external="true"
            class="hover:underline lg:text-center"
          >
            <div
              class="lg:flex lg:flex-wrap lg:justify-center"
              v-html="cinemaUrl"
            />
          </NuxtLink>
        </CinemaInfoTile>

        <CinemaInfoTile :icon="SVG_ICON.SEAT" class="order-4">
          <span
            class="text-dark lg:text-center lg:text-white dark:text-white lg:dark:text-white"
            v-text="`${cinema.auditoriumCount} ${t('auditoriums')}`"
          />
          <span class="text-dark lg:hidden dark:text-white">&nbsp;|&nbsp;</span>
          <span
            class="text-dark lg:text-center lg:text-white dark:text-white lg:dark:text-white"
            v-text="`${cinema.seatCount} ${t('seats')}`"
          />
        </CinemaInfoTile>
      </div>

      <div
        class="w-full md:w-1/2"
        v-html="
          cinema.seoText?.length ? cinema.seoText : cinema.meta.description
        "
      />
    </div>

    <GoogleMaps :cinema="cinema" class="h-96" />
  </div>
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const props = defineProps<Props>()

const { t } = useI18n()

const cinemaUrl = computed(() =>
  (props.cinema.url?.split(/(?<=\/|\.)/g) || [])
    .map((block) => `<span>${block}</span>`)
    .join('')
)

defineOptions({
  name: 'CinemaInfo',
})
</script>

<i18n>
de:
  auditoriums: "Säle"
  seats: "Sitzplätze"
es:
  auditoriums: "Salas"
  seats: "Asientos"
</i18n>
