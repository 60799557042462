<template>
  <TabbedNavigation :links class="mt-1" />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const props = defineProps<Props>()

const { t } = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const getRouteBaseName = useRouteBaseName()

const links = computed(() => {
  const routes = [
    {
      name: t('program'),
      routeName: ROUTE_NAMES.CINEMA_PROGRAM,
      shouldRender: true,
    },
    {
      name: t('info'),
      routeName: ROUTE_NAMES.CINEMA_INFO,
      shouldRender: true,
    },
    {
      name: t('vouchers'),
      routeName: ROUTE_NAMES.CINEMA_VOUCHERS,
      shouldRender: props.cinema.isVoucherBuyEnabled,
    },
    {
      name: t('shop'),
      routeName: ROUTE_NAMES.CINEMA_SHOP,
      shouldRender: props.cinema.isShopEnabled,
    },
    {
      name: t('faq'),
      routeName: ROUTE_NAMES.CINEMA_FAQ,
      shouldRender: !!props.cinema.faq,
    },
  ]

  return (
    routes
      .filter(({ shouldRender }) => shouldRender)
      .map(({ routeName, name }) => ({
        to: localeRoute({ name: routeName }),
        current: getRouteBaseName(route) === routeName,
        name,
      })) ?? []
  )
})

defineOptions({
  name: 'CinemaNavigation',
})
</script>

<i18n>
de:
  program: "Programm"
  info: "Über"
  vouchers: "Gutscheine"
  shop: "Shop"
  birthday: "Geburtstag"
  cinema: "kino"
  faq: "FAQ"
es:
  program: "Programación"
  info: "Información"
  vouchers: "Tarjeta regalo"
  shop: "Tienda"
  birthday: "Cumpleaños"
  cinema: "cine"
  faq: "FAQ"
</i18n>
