<template>
  <slot :city="city" :pending="pending" />
</template>

<script lang="ts" setup>
interface Props {
  citySlug: string
  throwError?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  throwError: false,
})

const COMPONENT_NAME = 'CityFetch'

const { data, pending, execute } = await useAsyncData(
  getGqlFetchKey(COMPONENT_NAME, props.citySlug),
  () =>
    GqlFetchCity({
      urlSlug: props.citySlug,
    }),
  { immediate: false }
)

await execute()

if (!data.value?.city?.id && props.throwError) {
  showError({
    statusCode: 404,
    statusMessage: 'cityNotFound',
  })
}

const city = computed(() => data.value?.city)

defineOptions({
  name: COMPONENT_NAME,
})
</script>
