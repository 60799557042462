import type { Cinema } from '#gql/default'

export default async function useCinema({
  variables,
  throwError,
}: {
  variables: { urlSlug: string; cityUrlSlug: string; design?: string }
  throwError?: boolean
}) {
  const { backendToken } = useBackendToken()
  const { isWidgetMode } = useWidgetMode()
  const { design, applyDesign } = useDesign()

  if (backendToken.value) {
    useGqlHeaders({ Authorization: `Bearer ${backendToken.value}` })
  }

  const { data, status } = await useAsyncGql(
    'FetchCinemaForCinemaPage',
    variables
  )

  if (throwError && !data.value?.cinema) {
    showError({
      statusCode: 404,
      statusMessage: 'cinemaNotFound',
    })
  }

  if (isWidgetMode.value && data.value?.cinema?.design) {
    design.value = data.value.cinema.design
    applyDesign()
  }

  const cinema = computed(() => data.value?.cinema as Cinema | undefined)
  const pending = computed(() => status.value === 'pending')

  return {
    cinema,
    pending,
  }
}
