import type { CinemaCardsQueryFilters } from '@portal/components/cinema/cards/index.vue'
import type {
  FetchCinemasForCinemaCardsQueryVariables,
  CinemaType,
  Cinema,
} from '#gql/default'

export default async function useCinemas({
  fetchOperation,
  fetchFallbackResults,
  filters,
  urlPrefix,
  preselected,
  first = 10,
}: {
  fetchOperation: 'FetchCinemasForCinemaCards'
  fetchFallbackResults: boolean
  filters?: UrlFilter[]
  urlPrefix?: string
  preselected: CinemaCardsQueryFilters
  first?: number
}) {
  const { fetchParams, hasFiltersApplied } = useFetchParams({
    filters,
    urlPrefix,
    preselected,
  })

  const variables: Ref<FetchCinemasForCinemaCardsQueryVariables> = ref(
    getVariables(false, 1)
  )
  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const {
    results,
    pending,
    hasMorePages,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
  } = useFetchResults({
    data,
    status,
    fetchFallbackResults,
    fetchParams,
    variables,
    getVariables,
  })

  function getVariables(fallbackResults = false, page = 1) {
    const { city, types, search } = fetchParams.value
    const proximity = city ? { city, distance: 25 } : undefined
    const params = fallbackResults
      ? {}
      : {
          types: types as CinemaType[],
          search,
          featureFlags: preselected.featureFlags, // keep preselected feature flags
        }

    return {
      proximity,
      ...params,
      first,
      page,
    }
  }

  const cinemas = computed(() => results?.value as unknown as Cinema[])

  return {
    cinemas,
    pending,
    hasMorePages,
    hasFiltersApplied,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
  }
}
