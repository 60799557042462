<template>
  <CmsPage v-if="page" :page />
  <GenericPageCinemaInfo v-else :cinema="cinema" />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const { t } = useI18n()
const { page } = useCms()

usePageMetas({
  title: `${cinema.name} | ${t('info')}`,
  description: t('description', { cinemaName: cinema.name }),
  ...cinema.meta,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/info',
    de: '/kino/[citySlug]/[cinemaSlug]/info',
    es: '/cine/[citySlug]/[cinemaSlug]/informacion',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugInfo',
})
</script>

<i18n>
de:
  info: "Informationen zum Kino"
  description: "Informationen zum Kino {cinemaName}."
es:
  info: "Información del cine"
  description: "Información del cine {cinemaName}."
</i18n>
