import type { LinkParam } from '#gql/default'

export default function updateOrAddLinkParam(
  params: LinkParam[],
  key: string,
  value: string | string[]
): void {
  const paramIndex = params.findIndex((param) => param.key === key)
  if (paramIndex !== -1) {
    params[paramIndex].value = Array.isArray(value)
      ? value.join(',')
      : value ?? ''
  } else {
    params.push({
      key,
      value: Array.isArray(value) ? value.join(',') : value ?? '',
    })
  }
}
