<template>
  <div
    ref="container"
    data-cinema-cards-horizontal
    class="group-data-[default-horizontal-spacing=true]:container-bleed"
  >
    <HorizontalMediaScroller
      :slides="cinemas || skeletons"
      :hide-buttons="!!renderSkeletons"
      :per-page="slidesVisible"
      :classes="{
        slide: cssClasses.slide,
      }"
      image-selector="[data-image]"
    >
      <template #default="{ slide: cinema, index: key }">
        <CinemaCardSkeleton v-if="renderSkeletons" :key />
        <CinemaCard
          v-else
          :key="cinema.id"
          :render-above-the-fold="renderAboveTheFold && key <= 2"
          :cinema
          :link-params
        />
      </template>
    </HorizontalMediaScroller>
  </div>
</template>

<script lang="ts" setup>
import type { Cinema, LinkParam } from '#gql/default'

interface Props {
  cinemas?: Cinema[]
  linkParams?: LinkParam[]
  renderAboveTheFold?: boolean
  renderSkeletons?: number
}

const props = defineProps<Props>()

const container = ref(null)
const { width } = useElementSize(container)

const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

const slidesVisible = computed(() => {
  if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_3XL) {
    return 5
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_2XL) {
    return 4
  } else if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_XL) {
    return 3
  } else {
    return 2
  }
})

const cssClasses = {
  container:
    'flex group relative px-3 sm:px-6 xl:px-8 2xl:px-8 overflow-hidden',
  slide:
    'flex flex-col grow-0 shrink-0 px-1 sm:px-2 basis-1/2 @xl:basis-1/3 @2xl:basis-1/4 @3xl:basis-1/5',
}

defineOptions({
  name: 'CinemaCardsHorizontal',
})
</script>
