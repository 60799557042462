import type {
  FetchMoviesForMovieCardsQueryVariables,
  FetchMoviesForMovieCarouselQueryVariables,
  FetchMoviesForMovieListQueryVariables,
  MoviePeriod,
} from '#gql/default'

export default async function useMovies({
  fetchOperation,
  fetchFallbackResults,
  filters,
  urlPrefix,
  preselected,
  first = 10,
}: {
  fetchOperation:
    | 'FetchMoviesForMovieCards'
    | 'FetchMoviesForMovieList'
    | 'FetchMoviesForMovieCarousel'
  fetchFallbackResults: boolean
  filters?: UrlFilter[]
  urlPrefix?: string
  preselected: {
    city?: string
    search?: string
    genres?: string[]
    contentRatings?: string[]
    periods?: string[]
  }
  first?: number
}) {
  const { fetchParams, hasFiltersApplied } = useFetchParams({
    filters,
    urlPrefix,
    preselected,
  })

  const variables: Ref<
    | FetchMoviesForMovieCardsQueryVariables
    | FetchMoviesForMovieCarouselQueryVariables
    | FetchMoviesForMovieListQueryVariables
  > = ref(getVariables(false, 1))
  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const {
    results,
    pending,
    hasMorePages,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
  } = useFetchResults({
    data,
    status,
    fetchFallbackResults,
    fetchParams,
    variables,
    getVariables,
  })

  function getVariables(fallbackResults = false, page = 1) {
    const { city, search, contentRatings, periods, genres } = fetchParams.value
    const cinemaProximity = city ? { city, distance: 25 } : undefined
    const params = fallbackResults
      ? {}
      : {
          contentRatings,
          periods: periods as MoviePeriod[],
          genres,
          search,
        }

    return {
      cinemaProximity,
      ...params,
      first,
      page,
    }
  }

  return {
    results,
    pending,
    hasMorePages,
    hasFiltersApplied,
    hasPreviousPages,
    hasFallbackResults,
    fetchMore,
    fetchParams,
  }
}
