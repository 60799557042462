<template>
  <CityFetch :city-slug="route.params.citySlug as string" :throw-error="true">
    <template #default="{ city, pending }">
      <NuxtPage v-if="!pending && city" :city="city" />
    </template>
  </CityFetch>
</template>

<script lang="ts" setup>
const route = useRoute()

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]',
    de: '/kino/[citySlug]',
    es: '/cine/[citySlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlug',
})
</script>
