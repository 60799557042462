<template>
  <div data-catalog-cinemas class="space-y-6">
    <LazyFilters v-if="filters?.length" v-bind="filterProps" />
    <CinemaCards v-bind="props" />
  </div>
</template>

<script lang="ts" setup>
import type { LinkParam } from '#gql/default'

export interface CinemaCardsQueryFilters {
  city?: string
  types?: string[]
  featureFlags?: string[]
  search?: string
}

interface Props {
  layout: CinemaCardsLayout
  filters?: UrlFilter[]
  linkParams?: LinkParam[]
  urlPrefix?: string
  fetchFallbackResults?: boolean
  preserveFilters?: boolean
  distance?: number
  itemsPerPage?: number
  enablePagination?: boolean
  enablePaginationOnScroll?: boolean
  renderAboveTheFold?: boolean
}

const props = defineProps<Props & CinemaCardsQueryFilters>()
const { t } = useI18n()

// @TODO should be refactored into a useFilters composable
const filterProps = computed(() => ({
  urlPrefix: props.urlPrefix,
  fetchComponent: resolveComponent('FiltersDataProviderCinemas'),
  fetchParams: {},
  filters: props.filters,
  searchPlaceholder: t('placeholder.search'),
  preselected: {
    city: props.city,
    types: props.types,
    featureFlags: props.featureFlags,
    search: props.search,
  },
}))

defineOptions({
  name: 'CatalogCinemas',
})
</script>

<i18n>
de:
  placeholder:
    search: "Kino suchen"
es:
  placeholder:
    search: "Buscar cine"
</i18n>
