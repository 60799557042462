<template>
  <div
    data-cinema-cards-vertical
    class="@lg:grid-cols-3 @xl:grid-cols-4 @2xl:grid-cols-5 grid grid-cols-2 gap-x-4 gap-y-8"
  >
    <template v-if="renderSkeletons">
      <CinemaCardSkeleton v-for="(_, key) in skeletons" :key />
    </template>
    <template v-else>
      <CinemaCard
        v-for="(cinema, key) in cinemas"
        :key="cinema.id"
        :render-above-the-fold="renderAboveTheFold && key <= 2"
        :cinema
        :link-params
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Cinema, LinkParam } from '#gql/default'

interface Props {
  cinemas?: Cinema[]
  linkParams?: LinkParam[]
  renderAboveTheFold?: boolean
  renderSkeletons?: number
}

const props = defineProps<Props>()

const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

defineOptions({
  name: 'CinemaCardsVertical',
})
</script>
