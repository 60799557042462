<template>
  <div
    v-if="!pending && !!banner?.sanitizedHtml"
    class="bg-neutral-dark-2 flex w-full justify-center rounded-md border px-8 py-4 shadow-lg"
  >
    <div
      class="max-w-prose whitespace-pre-line text-center text-sm leading-normal"
      v-html="banner.sanitizedHtml"
    />
  </div>
</template>

<script setup lang="ts">
import type { BannerPage, Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
  page: string
}

const props = defineProps<Props>()

const { data, pending } = await useAsyncGql('FetchBanner', {
  cinemaId: props.cinema.id,
  page: props.page as BannerPage,
})

const banner = computed(() => data.value?.banner ?? null)

defineOptions({
  name: 'CinemaBanner',
})
</script>
