<template>
  <div />
</template>

<script setup lang="ts">
import type { Cinema, Show } from '#gql/default'

interface Props {
  cinema: Cinema
  show: Show
}

defineProps<Props>()

const route = useRoute()
const { locale } = useI18n()
const localeRoute = useLocaleRoute()
const showSlug = route.params.showSlug

navigateTo(
  localeRoute(
    {
      name: ROUTE_NAMES.CINEMA_SHOW_SHOW_SLUG,
      params: {
        citySlug: route.params.citySlug,
        cinemaSlug: route.params.cinemaSlug,
        showSlug,
      },
    },
    locale.value
  )
)

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/show/[showSlug]/[showName]',
    de: '/kino/[citySlug]/[cinemaSlug]/vorstellung/[showSlug]/[showName]',
    es: '/cine/[citySlug]/[cinemaSlug]/evento/[showSlug]/[showName]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShowShowSlugShowName',
})
</script>
