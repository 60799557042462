<template>
  <CinemaBanner :cinema page="VOUCHERS" class="mb-8" />
  <div
    class="prose dark:prose-invert"
    v-html="
      cinema.hasOfflineVouchers
        ? ct(VOUCHER_STRINGS.SHOP_INTRO_WITH_OFFLINE_VOUCHERS)
        : ct(VOUCHER_STRINGS.SHOP_INTRO)
    "
  />
  <div id="kinoheld-eframe" />
</template>

<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const { t } = useI18n()

const { ct, fetchCinemaStrings } = useCinemaStrings()
await fetchCinemaStrings({
  keys: [
    VOUCHER_STRINGS.SHOP_INTRO,
    VOUCHER_STRINGS.SHOP_INTRO_WITH_OFFLINE_VOUCHERS,
  ],
  cinemaId: cinema.id,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `kino-${cinema.city.urlSlug}/${cinema.urlSlug}/vouchers`,
  floatingCart: false,
})

usePageMetas({
  title: `${cinema.name} | ${t('vouchers')}`,
  description: t('description', { cinemaName: cinema.name }),
  ...cinema.meta,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]/vouchers',
    de: '/kino/[citySlug]/[cinemaSlug]/gutscheine',
    es: '/cine/[citySlug]/[cinemaSlug]/codigos',
  },
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugVouchers',
})
</script>

<i18n>
de:
  vouchers: "Gutscheine"
  description: "Jetzt bei kinoheld Gutscheine für das Kino {cinemaName} schnell und bequem online kaufen."
es:
  vouchers: "Tarjeta regalo"
  description: "Compra códigos de cine para {cinemaName} rápidamente y cómodamente en línea con cine.entradas.com."
</i18n>
